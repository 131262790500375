import React from "react";

const TypeA = ({
  className,
  imgRef,
  imgAlt,
  imgHeight,
  imgWidth,
  ctaTitle,
  ctaText,
}) => {
  return (
    <div className="callout-type-a">
      <div className={`callout-wrapper ${className ? className : ""}`}>
        <div className="image-wrapper">
          <img
            src={imgRef}
            alt={imgAlt}
            height={imgHeight}
            width={imgWidth}
          />
        </div>
        <h4 className="callout-title" dangerouslySetInnerHTML={{ __html: ctaTitle }} />
        <p className="callout-text" dangerouslySetInnerHTML={{ __html: ctaText }}
        />
      </div>
    </div>
  )
}

export default TypeA
